import convert from "./convert-units/lib/convert_units";

const unitMapping = {
  "kg/cm²": "Kg_cm_sq",
  "°c": "C",
  "µm": "micro",
};

const getUnitForLibrary = (unit) => {
  if (unit.toLowerCase() in unitMapping) return unitMapping[unit.toLowerCase()];
  return unit;
};

function getPrecision(value) {
  const valueString = value.toString();
  const decimalIndex = valueString.indexOf(".");

  if (decimalIndex === -1) {
    return 0;
  }

  return valueString.length - decimalIndex - 1;
}

export default function unitConvertor(value, fromUnit, toUnit) {
  if (fromUnit === toUnit) return value;
  let convertedValue = value;
  try {
    convertedValue = convert(value)
      .from(getUnitForLibrary(fromUnit.trim()))
      .to(getUnitForLibrary(toUnit.trim()));
  } catch (e) {
    console.log(`[ERROR] Failed to convert unit (from: ${fromUnit}, to: ${toUnit}), error: `, e);
    return convertedValue;
  }

  return convertedValue;
}

export const isCovertPossible = (from, to) => {
  let possibilities;
  try {
    possibilities = convert().from(getUnitForLibrary(from)).possibilities();
  } catch (e) {
    console.log(
      "[ERROR] Failed to check unit conversion possibility, error: ",
      e
    );
    return false;
  }
  return possibilities?.includes(getUnitForLibrary(to)) || false;
};
