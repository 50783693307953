import { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Tooltip,
} from "@mui/material";
import { useParams } from "react-router";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { createDisciplineGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";


const keys = [
  { id: 0, label: "Electro-technical" },
  { id: 1, label: "Others" },
  { id: 2, label: "mass & volume" },
  { id: 3, label: "thermal" },
];

export default function CreateDiscipline(props) {
  const createdisciplineGuides = createDisciplineGuide();
  const [disciplineName, setDisciplineName] = useState("");
  const [environmentalConditions, setEnvironmentalConditions] = useState("");
  const [remarks, setRemark] = useState("");
  const [key, setKey] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [issueNo, setIssueNo] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [amendmentNo, setAmendmentNo] = useState("");
  const [amendmentDate, setAmendmentDate] = useState("");
  const [environmentCondition, setEnvironmentCondition] = useState({
    startTemp: null,
    endTemp: null,
    startHumidity: null,
    endHumidity: null,
  });
  const params = useParams();
  const [errormodalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editAccess, setEditAccess] = useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const onSubmit = () => {
    var jsonData = {
      name: disciplineName,
      disciplineKey: key.id,
      environmentalConditions: environmentalConditions,
      startTemp: environmentCondition?.startTemp,
      endTemp: environmentCondition?.endTemp,
      startHumidity: environmentCondition?.startHumidity,
      endHumidity: environmentCondition?.endHumidity,
      documentNumber,
      issueNo: issueNo ? issueNo : null,
      issueDate: issueDate ? moment(issueDate).format("YYYY-MM-DD") : null,
      amendmentNo,
      amendmentDate: amendmentDate
        ? moment(amendmentDate).format("YYYY-MM-DD")
        : null,
    };
    let url = BASE_URL;
    (() =>
      params.id
        ? axiosWithToken.patch(url + `discipline/${params.id}`, jsonData)
        : axiosWithToken.post(url + "discipline", jsonData))()
      .then((res) => {
        props.setLoader(false);
        toast("new discipline added!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        // props.setLoader(false);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "onSubmit" ,"Create Discipline")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const getStandardIdList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `discipline/${params.id}`)
      .then((res) => {
        let newResData = res.data[0];
        setDisciplineName(newResData?.name);
        setKey(keys.filter((k) => k.id == newResData?.disciplineKey)[0] || "");
        setDocumentNumber(newResData?.documentNumber);
        setIssueNo(newResData?.issueNo);
        setIssueDate(newResData?.issueDate);
        setAmendmentNo(newResData?.amendmentNo);
        setAmendmentDate(newResData?.amendmentDate);
        setEnvironmentCondition((prevState) => ({
          ...prevState,
          startTemp: newResData?.startTemp,
          endTemp: newResData?.endTemp,
          startHumidity: newResData?.startHumidity,
          endHumidity: newResData?.endHumidity,
        }));
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getStandardIdList" ,"Create Discipline")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) {
      getStandardIdList();
    }
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          {params.id ? "Update Discipline" : "Create New Discipline"}
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              createdisciplineGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="discipline_create_discipline"
            label="Discipline name *"
            size="small"
            fullWidth
            inputProps={{
              autoComplete: 'off',
            }}
            value={disciplineName}
            variant="outlined"
            onChange={(e) => setDisciplineName(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <Autocomplete
            size="small"
            id="discipline_create_keyname"
            options={keys}
            value={key}
            renderInput={(params) => <TextField {...params} label="Key name" />}
            onChange={(_, val) => {
              setKey(val);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="discipline_create_docno"
            label="Document Number"
            size="small"
            fullWidth
            inputProps={{
              autoComplete: 'off',
            }}
            value={documentNumber}
            variant="outlined"
            onChange={(e) => setDocumentNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="discipline_create_master_issueno"
            label="Issue Number"
            size="small"
            fullWidth
            type="integer"
            value={issueNo}
            variant="outlined"
            onChange={(e) => setIssueNo(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3} id="discipline_create_issuedate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Issue Date"
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              value={issueDate ? new Date(issueDate) : null}
              onChange={(e) => setIssueDate(e)}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="discipline_create_amendnumber"
            label="Amendment Number"
            size="small"
            fullWidth
            inputProps={{
              autoComplete: 'off',
            }}
            value={amendmentNo}
            variant="outlined"
            onChange={(e) => setAmendmentNo(e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          lg={3}
          id="discipline_create_ammenddate"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Amendment Date"
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              value={amendmentDate ? new Date(amendmentDate) : null}
              onChange={(e) => setAmendmentDate(e)}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3}></Grid>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            In House Environment Condition
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="discipline_create_discipline"
            label="Start Temperature"
            size="small"
            fullWidth
            value={environmentCondition?.startTemp || ""}
            variant="outlined"
            inputProps={{
              autoComplete: 'off',
            }}
            onChange={(e) =>
              setEnvironmentCondition((prevState) => ({
                ...prevState,
                startTemp: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="discipline_create_discipline"
            label="End Temperature"
            size="small"
            fullWidth
            value={environmentCondition?.endTemp || ""}
            inputProps={{
              autoComplete: 'off',
            }}
            variant="outlined"
            onChange={(e) =>
              setEnvironmentCondition((prevState) => ({
                ...prevState,
                endTemp: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="discipline_create_discipline"
            label="Start Humidity"
            size="small"
            fullWidth
            inputProps={{
              autoComplete: 'off',
            }}
            value={environmentCondition?.startHumidity || ""}
            variant="outlined"
            onChange={(e) =>
              setEnvironmentCondition((prevState) => ({
                ...prevState,
                startHumidity: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="discipline_create_discipline"
            label="End Humidity"
            size="small"
            fullWidth
            value={environmentCondition?.endHumidity || ""}
            variant="outlined"
            inputProps={{
              autoComplete: 'off',
            }}
            onChange={(e) =>
              setEnvironmentCondition((prevState) => ({
                ...prevState,
                endHumidity: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Tooltip title={!(editAccess?.includes(1) || editAccess?.includes(0)) ? "You don't have access" : ''}>
        <Button
          id="discipline_create_save"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
          onClick={() => {
            if (disciplineName && key) {
              props.setLoader(true);
              onSubmit();
            } else {
              toast.error("Discipline name and key are compulsory");
            }
          }}
        >
          {params.id ? "Update" : "Save"}
        </Button>
        </Tooltip>
      </Toolbar>

      <ToastContainer />
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </Paper>
  );
}
