import { BASE_URL } from "../../global";
import axiosWithToken from "./axiosTokenConfig";


let cache = {};

const convertDegreeCandOhm = async (val, from, to, row, BASE) => {
 
    try{
        let referencedData = null;
        // get referernced data from database
        if(cache["unitConvDegreeCOhm"]?.[row[1 + BASE]]){
            referencedData = cache["unitConvDegreeCOhm"]?.[row[1 + BASE]];
        } else{
            const url = `${BASE_URL}dynamic`;
            const query = `SELECT * from unitConvDegreeCOhm where degreeC=${row[1 + BASE]} limit 1`;
            referencedData = await axiosWithToken.post(url, { query });
            referencedData = referencedData?.data?.[0];
            cache["unitConvDegreeCOhm"] = cache["unitConvDegreeCOhm"] || {};
            cache["unitConvDegreeCOhm"][row[1 + BASE]] = referencedData;
        }
        

        // conversion formula
        val = ((val-referencedData?.ohm)/referencedData?.diff)+Number(row[1 + BASE]);
        return val;
    }catch(e){
        console.log("Error in customUnitConversion", e);
        return val;
    }
}

const convertDegreeCandMV = async (val, from, to, row, BASE) => {

}

const convertGramAndNewton = (val, from, to, row, BASE) => {
    console.log('convertGramAndNewton, val, from', val, from, from === "g");
    if(from === "g") return val*(1000/9.80665);
    if(from === "N") return val*(9.80665/1000);
}

export const customUnitConvCombinations = [
    ["°c", "Ω"],
    ["Ω", "°c"],
    ["°c", "mV"],
    ["mV", "°c"],
    ["N", "g"],
    ["g", "N"]
];
export const containsCustomUnitPair = (inputPair) => {
    const inputSet = new Set(inputPair);
    return customUnitConvCombinations.some(pair => new Set(pair).size === inputSet.size && pair.every(unit => inputSet.has(unit)));
}

export const customUnitConversion = async (val, from, to, row = null, BASE = null) => {
    if(from === to) return val

    // 1. case 1: °C to Ω, and vice versa
    if(["°c", "Ω"].includes(from) && ["°c", "Ω"].includes(to)){
        return await convertDegreeCandOhm(val, from, to, row, BASE)
    }

    // 2. case 2: mV to °C 
    if(["°c", "mV"].includes(from) && ["°c", "mV"].includes(to)){
        return await convertDegreeCandMV(val, from, to, row, BASE)
    }

    // 3. Newton to gram and vice versa
    if(["N", "g"].includes(from) && ["N", "g"].includes(to)){
        return convertGramAndNewton(val, from, to, row, BASE);
    }

    // clean cache after 3 minutes
    // setTimeout(() => {
    //     cache = {};
    // }, 180000);
}