// Use This URL for testing
const URL = "ecogreencertificates.com";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "State Bank OF India, CBD Belapur Navi Mumbai",
  "Bank A/c. No.": "39813124209",
  "IFSC Code": "SBIN0061520",
};
export const GST_NUMBER = "27DSVPK5381Q1ZH";

export const DISABLED_MODULE =["Courier Register","Expenses","Audit","Document Upload","Purchase"
,"DUC Master","Department","Request Form","CoordinatesAddress","Settings","Datasheet Template","Discipline","ClientsUser","OfflineMode","WCR Report","Training Resources","	Department Analytics"]; 

export const DEVICE_TYPE = ["desktop", "tablet", "mobile"];
