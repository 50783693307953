import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { Autocomplete, Modal, useMediaQuery } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "./../../global";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { showComponent } from "../helper/helpers";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExcelDownload from "../../utils/components/excelDownload";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

let notNeededColumn = [
  "clientId",
  "insId",
  "id",
  "workOrderNumber",
  "certificateNumber",
  "totalReadings",
];
export default function WorkReport() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [page, setPage] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [datasheet, setDatasheet] = useState([]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [enableDisable, setEnableData] = React.useState("");
  const [usersArray, setUsersArray] = React.useState([]);
  const [allUsers, setUsers] = React.useState([]);
  const [finalData, setFinalData] = React.useState([]);
  const [engineerName, setEngineerName] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [type2AllUsers, setType2AllUsers] = React.useState([]);
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [userId, setUserId] = React.useState(localStorage.getItem("id"));
  const [branch, setBranch] = React.useState(localStorage.getItem("branch"));
  const [result, setResult] = React.useState([]);
  const showJobAssign = userType !== "2" ? true : false;
  const validCompanies = localStorage.getItem("validClients");
  const statusArray = ["Assign", "Pending", "Completed"];
  const location = useLocation();
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [searched, setSearched] = React.useState(false);

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const columns = [
    {
      id: "id",
      label: "Sr. No.",
      align: "left",
      minWidth: 40,
    },
    {
      id: "customerName",
      label: "Client",
      align: "left",
      minWidth: 40,
    },
    {
      id: "serviceReqNumber",
      label: "service Request Number",
      align: "left",
      minWidth: 40,
    },
    {
      id: "jobNumber",
      label: "Job Number",
      align: "left",
      minWidth: 40,
    },
    {
      id: "instrumentName",
      label: "Instrument Name",
      align: "left",
      minWidth: 40,
    },
    {
      id: "range",
      label: "Range",
      align: "left",
      minWidth: 40,
    },
    {
      id: "lc",
      label: "LC",
      align: "left",
      minWidth: 40,
    },
    {
      id: "DUCID",
      label: "DUC ID",
      align: "left",
      minWidth: 40,
    },
    {
      id: "serialNumber",
      label: "Serial Number",
      align: "left",
      minWidth: 40,
    },
    {
      id: "dateOfCalibration",
      label: "Date of Calibration",
      minWidth: 40,
      align: "left",
    },
    {
      id: "nextDueDate",
      label: "Due Date",
      minWidth: 40,
      align: "left",
    },
    {
      id: "assignedUser",
      label: "Assigned To",
      minWidth: 40,
      align: "left",
    },
    {
      id: "Status",
      label: "Status",
      minWidth: 40,
      align: "left",
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const processEnableDisable = (enableDisable) => {
    if (enableDisable.length > 0) {
      const newResult = enableDisable
        .map(function (item) {
          if (item) {
            let [id, closed] = item.split(",");
            return closed === "true" ? id : null;
          }
        })
        .filter(function (item) {
          return item !== undefined && item !== null;
        });

      return newResult;
    }

    return [];
  };

  const getQuery = (count) => {
    let whereConditions = "where ds.status = 1 ";
    let pagination_settings = "";
    
    if (status) {
      if (status === "Assign") {
          whereConditions += ` ${engineerName ? `AND ds.assignedUser like ${engineerName.split(",")[0]}` : ''}`;
      } else if (status === "Pending") {
          whereConditions += `AND ds.totalReadings = 0${engineerName ? ` AND ds.assignedUser like ${engineerName.split(",")[0]}` : ''}`;
      } else if (status === "Completed") {
          whereConditions += `AND ds.totalReadings != 0${engineerName ? ` AND ds.assignedUser like ${engineerName.split(",")[0]}` : ''}`;
      }
  }
          
    if (to != null && from != null) {
      whereConditions += ` and ds.calibrationDate between '${moment(
        from
      ).format("YYYY-MM-DD")}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (userType == "2") {
      whereConditions +=
        whereConditions.length > 0
          ? `and (ds.assignedUser=${userId}`
          : `where (ds.assignedUser=${userId}`;
      whereConditions += ` or ds.createdby=${userId}`;
      whereConditions +=
        whereConditions.length > 0
          ? ` or cust.id in (${validCompanies}))`
          : ` where cust.id in (${validCompanies})`;
    }

    whereConditions +=
      userType == 4 && branch && branch.length
        ? (whereConditions ? " and " : "") + ` cust.branch in (${branch})`
        : "";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `select ds.clientId,cust.companyName,ds.totalReadings, ins.instrumentName,ins.id as insId,ds.id,ds.workOrderNumber,ds.certificateNumber,ds.calibrationDate,ds.calProcRefNo,replace(replace( ds.ranges , '#',''),'|',' to ') as ranges,replace(replace( ds.lc , '#',''),'|',' to ') as lc,ds.DUCID,ds.identificationNo,ds.jobNumber,ds.nextDueDate,ds.dateOfIssue,ds.assignedUser,ds.serialNumber,ds.serviceReqNumber from datasheets as ds left join instruments as ins on ds.instrumentId = ins.id left join clients as cust on ds.clientId = cust.id  ${whereConditions} order by ds.id DESC ${pagination_settings}`,
    };
    if (count)
      data.query = `select count(*) as no_of_rows from datasheets as ds left join instruments as ins on ds.instrumentId = ins.id left join clients as cust on ds.clientId = cust.id  ${whereConditions} order by ds.id DESC ${pagination_settings}`;

    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchDatasheet() {
    let data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setDatasheet([...res.data]);
        const formattedData = res.data?.map((row) => {
          const assignedUserLabel = row.assignedUser !== null 
          ? allUsers?.find(user => {
              const id = parseInt(user.label?.split(',')[0]?.trim());
              return id === row.assignedUser;
            })?.label?.split(',')[1]?.trim() || ""
          : "";
          const totalReadingsstatus = row.totalReadings === 0 ? "Pending" : "Completed";
          console.log({assignedUserLabel})
          return {
            ...row,
            assignedUser: assignedUserLabel || "",
            status: totalReadingsstatus || ""
          };
        });
        setFinalData(formattedData);
        // setFinalData([...res.data]);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getUserList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "users?_where=(type,eq,2)")
      .then((res) => {
        setUsersArray(res.data);
        let data = res.data;
        let newData = [];
        data.map((e) => newData.push(`${e.id}, ${e.userName}`));
        setType2AllUsers(newData);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const initializeuserData = () => {
    var pushCArray = [];
    for (let i = 0; i < usersArray.length; i++) {
      pushCArray.push({
        label: usersArray[i].id + ", " + usersArray[i].userName,
      });
    }
    setUsers(pushCArray);
  };

  const saveUserAndDsID = (userId, dsId) => {
    let url = BASE_URL;

    var data = {};
    var dsIdArr = dsId.split(",");
    if (userId) {
      dsIdArr.forEach((element) => {
        let data = {
          query: `UPDATE datasheets SET assignedUser = ${userId} WHERE id=${element};`,
        };
        axiosWithToken
          .post(BASE_URL + `dynamic`, data)
          .then((res) => {
            toast("Datasheet Assigned.");
          })
          .catch((error) => {
            if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
          });
      });
    }
  };

  const search = () => {
    fetchDatasheet();
    setSearched(true);
  };

  const clearSearch = () => {
    // setSearchKey("");
    setStatus(null);
    setEngineerName("");
    setSearched(false); 
  };

  useEffect(() => {
    setResult(processEnableDisable(enableDisable));
  }, [enableDisable]);

  useEffect(() => {
    getTotalRows();
    getUserList();
  }, []);

  useEffect(() => {
    initializeuserData();
  }, [usersArray]);

  useEffect(() => {
    if (searched==false){
      fetchDatasheet();
    }
    if (searched) {
      setPage(0);
    }
  }, [rowsPerPage, page,allUsers,searched]);

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const filterSection = () => (
    <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            justifyContent: "start",
            width: "100%",
          }}
        >
          <div
          className="mb-2"
          style={{
            display: location.pathname === "/dashboard" ? "none" : "flex",
            float: "right",
            justifyContent: "flex-start",
            flexWrap:"wrap",
            gap:"10px"
          }}
        >
          {showJobAssign && result && result.length ? (
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 150,
              }}
              size="small"
              id="combo-box-demo"
              options={allUsers}
              renderInput={(params) => (
                <TextField {...params} label="Job Assign" />
              )}
              onInputChange={(event, newInputValue) => {
                saveUserAndDsID(
                  parseInt(newInputValue.split(",")[0]),
                  result ? result.toString() : ""
                );
              }}
            />
          ) : ""}

          <div>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From"
                value={from ? new Date(from) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setFrom(newValue);
                  setSearched(false);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </div>

          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="To"
                value={to ? new Date(to) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setTo(newValue);
                  setSearched(false);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </div>
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 150,
            }}
            size="small"
            value={status}
            onChange={(event, newValue) => {
              setStatus(newValue);
              setSearched(false);
            }}
            options={statusArray}
            renderInput={(params) => <TextField {...params} label="Status" />}
          />
          <br />
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
            }}
            size="small"
            value={engineerName}
            // sx={{ minWidth: 200 }}
            onChange={(event, newValue) => {
              setEngineerName(newValue);
              setSearched(false);
            }}
            options={type2AllUsers}
            renderInput={(params) => (
              <TextField {...params} label="Engineer Name" />
            )}
          />

          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
          >
            {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
          <ExcelDownload
            finalData={finalData}
            notNeededColumn={notNeededColumn}
          />
        </div>
        </div>
  );

  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
       <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
            {filterModal()}
        {!isMobile && filterSection()}

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <br />
        <div style={{ margin: "10px" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="table-responsive"
            size="small"
            id="datatable-keytable"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {finalData?.length > 0 ? (
                finalData.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell>
                        {rowsPerPage * Number(page) + index + 1}
                      </TableCell>
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>{row.serviceReqNumber}</TableCell>
                      <TableCell>{row.jobNumber}</TableCell>
                      <TableCell>{row.instrumentName}</TableCell>
                      <TableCell>
                        {row.ranges
                          ?.replaceAll("||", ", ")
                          ?.replaceAll("|", " to ")
                          ?.replaceAll("#", "")}
                      </TableCell>
                      <TableCell>
                        {row.lc
                          ?.replaceAll("||", ", ")
                          ?.replaceAll("|", " to ")
                          ?.replaceAll("#", "")}
                      </TableCell>
                      <TableCell>{row.DUCID}</TableCell>
                      <TableCell>{row.serialNumber}</TableCell>
                      <TableCell>
                        {row.calibrationDate
                          ? moment(row.calibrationDate).format("DD-MM-YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row.nextDueDate
                          ? moment(row.nextDueDate).format("DD-MM-YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>
                      {row.assignedUser}
                      </TableCell>
                      <TableCell>
                      {row.status}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center">
                   <h6><b>Data Not Found</b></h6>
                  </TableCell>
                </TableRow>
              )} 
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
            colSpan={3}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            ActionsComponent={TablePaginationActions}
          />
          <ToastContainer />
        </div>
      </TableContainer>
    </Paper>
  );
}
