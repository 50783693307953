import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { TextField, Autocomplete, Table, Grid, Modal, useMediaQuery } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { showComponent } from "../../helper/helpers";
import "../../helper/helper.css";
import { userTypes } from "../../../constants/masterUserConstants";
import { usersListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



const columns = [
  { id: "id", label: "Sr. No.", minWidth: 20 },
  { id: "userName", label: "User Name", minWidth: 40 },
  { id: "password", label: "Password", minWidth: 40 },
  { id: "type", label: "Type", minWidth: 40 },
  { id: "branches", label: "Branches", minWidth: 40 },
  { id: "modules", label: "Modules", minWidth: 60 },
];

function createData(id, userName, password, type, branches, modules) {
  return {
    id,
    userName,
    password,
    type: userTypes.filter((e) => e.id == type)?.[0]?.label,
    branches,
    modules,
  };
}

export default function UserList(props) {
  const usersListGuides = usersListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [quotationList, setQuotationList] = React.useState([]);
  const [userData, setUserData] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "User Name");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const userType = localStorage.getItem("type");
  const [searched, setSearched] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const [title, setTitle] = useState("");
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    props.setLoader(true);
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `users/${id}`)
      .then((res) => {
        toast("User is deleted!");
        props.setLoader(false);
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "submitSiteDelete" ,"User List")}><u>Error: {err.message}</u></h6>);
        }
        // props.setLoader(false);
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };
  const storedSearchBy = sessionStorage.getItem("searched");
  const getQuery = (count) => {
    let whereConditions = "";
    let pagination = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "User Name": "users.userName",
        Branch: "branch.branchName",
      }[searchBy];
      whereConditions = `${colName} LIKE '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions !== "") {
        whereConditions += " and ";
      }

      whereConditions += `(date between ${moment(from).format(
        "YYYY-MM-DD"
      )} and ${moment(to).format("YYYY-MM-DD")})`;
    }

    if (rowsPerPage != -1) {
      pagination = ` LIMIT ${rowsPerPage} OFFSET ${
        Number(page) * rowsPerPage
      } `;
    }

    // let baseQuery = `SELECT id, userName, password, type, modules, branch FROM users WHERE status = 1`;

    let baseQuery = `SELECT users.id, users.userName, users.password, users.type, users.modules,users.branch, branch.branchName FROM users LEFT JOIN  branch ON users.branch = branch.id WHERE users.status = 1`;

    if (whereConditions !== "") {
      baseQuery += ` AND ${whereConditions}`;
    }

    let data = {
      query: `${baseQuery} ORDER BY id DESC ${pagination}`,
    };

    if (count) {
      data.query = `SELECT COUNT(id) AS no_of_rows FROM users WHERE status = 1 ${whereConditions !== ""  ? ` AND ${whereConditions}` :""}`;
    }

    return data;
  };

  function getClientList() {
    let data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        axiosWithToken.get(BASE_URL + "branch?_where=status=1").then((res2) => {
          let tmp = {};
          res2.data.map((b) => (tmp[b.id] = b));
          let users = res.data;
          var rowData = [];
          for (let i = 0; i < users.length; i++) {
            rowData.push(
              createData(
                users[i].id,
                users[i].userName,
                Array.from({ length: users[i].password.length }, (_, index) => (
                  <StarIcon key={index} fontSize="2px" />
                )),
                // users[i].password,
                users[i].type,
                (users[i].branch?.split(",") || [])
                  ?.map((e) => tmp[e]?.branchName)
                  ?.join(","),
                users[i].modules
              )
            );
          }
          setUserData(rowData);
        });
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getClientList" ,"User List")}><u>Error: {err.message}</u></h6>);
        }
      });
  }

  // api calls
  function getTotalRows() {
    let data = getQuery(true);
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getTotalRows" ,"User List")}><u>Error: {err.message}</u></h6>);
        }
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getClientList();
    getTotalRows();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("User Name");
    setSearchKey("");
    setSearched(false); 
  };
  
  useEffect(() => {
      getClientList();
      getTotalRows();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if(searched==false){
      getClientList();
      getTotalRows();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 5px", marginBottom: "2px" }}
    >
      <Grid item xs={6} sm={6} md={2} lg={2}>
      <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          size="small"
          id="users_list_searchby"
          value={searchBy}
          options={[
            { key: "username", label: "User Name" },
            { key: "branch", label: "Branch" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2}>
      <TextField
          id="users_list_searchbytext"
          label={"enter " + searchBy}
          inputProps={{
            autoComplete: 'off',
          }}
          size="small"
          variant="outlined"
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value)
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={1} lg={1} textAlign={"left"}>
      <Button
          id="users_list_searchbtn"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            searched ? clearSearch() : search();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
    </Grid>
  );

  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar>
          {showComponent("add") && (
            <Button
              id="users_list_createbtn"
              variant="contained"
              size="small"
              component={Link}
              to="/master/users/addUser"
              onClick={() => {
                setTitle("Master/ add new user");
              }}
            >
              <b>create user</b>
            </Button>
          )}
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
              <Button
                style={{ display: isMobile ? "block" : "none" }}
                onClick={handleFilterOpen}
              >
                <FilterAltIcon />
              </Button>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              usersListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
        </Box>
      </div>
      {!isMobile && filterSection()}
      <div
        className="mb-2"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        
       
       
      </div>
      <br />

      <div style={{ margin: "10px" }} id="users_list_table">
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
          width="100%"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData?.length > 0 ? (
              userData.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id == "id") {
                        value = page * rowsPerPage + (index + 1);
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <ButtonGroup
                        size="small"
                        aria-label="small button group"
                        className="action-container"
                      >
                        {showComponent("show") && (
                          <Tooltip title="Edit User" placement="top-start">
                            <Button
                              id="users_list_edit"
                              component={Link}
                              to={`/master/users/editUser/${row.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                        )}
                        {showComponent("delete") && (
                          <Tooltip title="Delete User" placement="top-start">
                            <Button
                              id="users_list_delete"
                              disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                              onClick={(e) => {
                                if (
                                  window.confirm("Really want to delete User?")
                                ) {
                                  submitSiteDelete(row.id);
                                }
                              }}
                            >
                              <DeleteIcon style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }}/>
                            </Button>
                          </Tooltip>
                        )}
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                 <h6><b>Data Not Found</b></h6>
                </TableCell>
              </TableRow>
            )} 
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
        <ToastContainer />
      </div>
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </TableContainer>
  );
}
